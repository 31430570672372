import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { cssVar } from "@chakra-ui/theme-tools"

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
  "3xl": "120em",
});

const styles = {
  global: {
    "html, body": {
      "scroll-behavior": "smooth",
    },
  },
};
const theme = {
  styles,
  breakpoints,
  colors: {
    green: {
      50: "#D5EDD6",
      100: "#E2F4CB",
      500: "#2BA530",
      600: "#2BA530",
      900: "#1A7A1E",
    },
  },
  fonts: {
    heading: "'DM Sans', sans-serif",
    body: "'DM Sans', sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "none",
        fontWeight: "normal",
      },
      variants: {
        primary: {
          bg: "green.500",
          color: "white",
          _hover: {
            bg: "green.900",
            color: "white",
          },
          _disabled: {
            bg: "gray.300",
            color: "gray.700",
          },
        },
        social: {
          color: "cyan.400",
          borderWidth: "1px",
          borderColor: "cyan.400",
          backgroundColor: "white",
          _hover: {
            color: "cyan.600",
            backgroundColor: "cyan.50",
            borderColor: "cyan.600",
          },
        },
      },
      sizes: {
        lg: {
          h: 12,
          minW: 12,
          fontSize: "lg",
          px: 6,
        },
        md: {
          h: 10,
          minW: 10,
          fontSize: "md",
          px: 4,
        },
        sm: {
          h: 8,
          minW: 8,
          fontSize: "sm",
          px: 3,
        },
        xs: {
          h: 6,
          minW: 6,
          fontSize: "xs",
          px: 2,
        },
      },
      defaultProps: {
        variant: "primary",
        size: "md",
        colorScheme: "green",
      },
    },
    Modal: {
      parts: ["dialog", "overlay", "body"],
      baseStyle: {
        dialog: {
          borderRadius: "none",
        },
        overlay: {
          bg: "rgba(0, 0, 0, 0.2);",
        },
        body: {
          px: "8",
          py: "8",
        },
      },
    },
    Input: {
      parts: ["field"],
      variants: {
        outline: {
          field: {
            bg: "white",
            borderRadius: "base",
            fontSize: "sm",
            px: 4,
            borderColor: "gray.200",
            color: "gray.600",
            _hover: {
              borderColor: "cyan.200",
            },
            _invalid: {
              boxShadow: "none",
            },
            _focus: {
              borderColor: "cyan.400",
            },
            _placeholder: {
              color: "gray.400",
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          bg: "white",
          borderRadius: "base",
          fontSize: "sm",
          px: 4,
          borderColor: "gray.200",
          color: "gray.600",
          _hover: {
            borderColor: "cyan.200",
          },
          _invalid: {
            boxShadow: "none",
          },
          _focus: {
            borderColor: "cyan.400",
          },
          _placeholder: {
            color: "gray.400",
          },
        },
      },
    },
    Switch: {
      variants: {
        pricing: {
          track: {
            _checked: {
              bg: "cyan.400",
            },
          },
        },
      },
      sizes: {
        xl: {
          container: {
            [cssVar("switch-track-width").variable]: "72px",
            [cssVar("switch-track-height").variable]: "32px",
          },
        },
      },
    },
  },
};

export default extendTheme(theme);
